.a1b2c3 {
  font-family: 'Merriweather', serif;
  padding: 20px;
  max-width: 800px;
  margin: 50px auto;
  color: white;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.d4e5f6 {
  font-size: 2em;
  margin-bottom: 20px;
}

.App {
  background-color: black;
  height: 100vh;
}

